/* stylelint-disable unit-disallowed-list */
@import 'vars';
@import 'grid';

.text-green {
  color: #00b1bc;
}
%colorGreen {
  color: #04aeba;
}

.info-song-coupon {
  color: #fff;
  width: 340px;
  position: fixed;
  text-align: left;
  left: 0;
  top: 150px;
  font-weight: bold;

  @include transition(left $timeHiden, transform $timeHiden);
  //transform: translateX(-500px);
  @include translate(-700px, 0);
}

.btn-app-rounded {
  display: block;
  border-radius: 28px;
  font-weight: 200;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  border: 4px solid transparent;
  font-family: Montserrat-Bold, Montserrat;
  background-color: #3b3b3b;
  color: #fff;

  &.btn-app-action {
    background-color: #ffe400;
    color: #333;
  }

  &.focus-item-keyboard {
    border: 4px solid #02aebc;
  }
}

.btn {
  &.focus-item-keyboard {
    border: 4px solid #02aebc !important;
  }
}
