@font-face {
  font-family: 'icomoon';
  src: url(/assets/fonts/icomoon.eot?efferl);
  src: url(/assets/fonts/icomoon.eot?efferl#iefix) format('embedded-opentype'),
    url(/assets/fonts/icomoon.ttf?efferl) format('truetype'),
    url(/assets/fonts/icomoon.woff?efferl) format('woff'),
    url(/assets/fonts/icomoon.svg?efferl#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete:before {
  content: '\e91f';
}
.icon-tv:before {
  content: '\e91d';
  color: #00c4cb;
}
.icon-tv-mobile:before {
  content: '\e91e';
  color: #00c4cb;
}
.icon-person:before {
  content: '\e91c';
  color: #fff;
}
.icon-star_comunity:before {
  content: '\e91b';
  color: #00c4cb;
}
.icon-whatsapp .path1:before {
  content: '\e911';
  color: rgb(0, 0, 0);
}
.icon-whatsapp .path2:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-peru .path1:before {
  content: '\e913';
  color: rgb(255, 75, 85);
}
.icon-peru .path2:before {
  content: '\e914';
  margin-left: -1.5380859375em;
  color: rgb(255, 255, 255);
}
.icon-peru .path3:before {
  content: '\e915';
  margin-left: -1.5380859375em;
  color: rgb(255, 75, 85);
}
.icon-check_select:before {
  content: '\e916';
  color: #fff;
}
.icon-creditcard:before {
  content: '\e917';
  color: #fff;
}
.icon-musicnote2:before {
  content: '\e918';
  color: #00c4cb;
}
.icon-musicnote:before {
  content: '\e919';
  color: #fff;
}
.icon-sms:before {
  content: '\e91a';
  color: #00c4cb;
}

.icon-vip .path1:before {
  content: '\e90a';
  color: rgb(255, 255, 255);
}
.icon-vip .path2:before {
  content: '\e90b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vip .path3:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-play-circle .path1:before {
  content: '\e90d';
  color: rgb(255, 255, 255);
}
.icon-play-circle .path2:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.2;
}
.icon-play-circle .path3:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-play:before {
  content: '\e910';
}
.icon-channel:before {
  content: '\e909';
  color: #828184;
}
.icon-heart-b:before {
  content: '\e905';
}
.icon-heart:before {
  content: '\e907';
}
.icon-list-add:before {
  content: '\e908';
}
.icon-catalog:before {
  content: '\e900';
  color: #828184;
}
.icon-mobile:before {
  content: '\e901';
  color: #828184;
}
.icon-explore:before {
  content: '\e903';
  color: #828184;
}
.icon-list:before {
  content: '\e902';
  color: #828184;
}
.icon-search:before {
  content: '\e904';
  color: #828184;
}
.icon-mic:before {
  content: '\e906';
  color: #a1a1a1;
}
