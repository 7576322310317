@keyframes ldio-i34nyigc4n {
  0% {
    transform: scale(0.45);
    -webkit-transform: scale(0.45);
    -moz-transform: scale(0.45);
    -o-transform: scale(0.45);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }

  100% {
    transform: scale(0.45);
    -webkit-transform: scale(0.45);
    -moz-transform: scale(0.45);
    -o-transform: scale(0.45);
  }
}

.ldio-i34nyigc4n div {
  box-sizing: content-box;
}

.ldio-i34nyigc4n {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  -webkit-transform: translateZ(0) scale(1);
  -moz-transform: translateZ(0) scale(1);
  -o-transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;

  > div {
    position: absolute;
    // width: 23.88px;
    // height: 23.88px;
    border-radius: 50%;
    top: 6.302em;
    animation: ldio-i34nyigc4n 1.7241379310344827s cubic-bezier(0.3, 0, 0.7, 1) infinite;
    -webkit-animation: ldio-i34nyigc4n 1.7241379310344827s cubic-bezier(0.3, 0, 0.7, 1) infinite;
    -moz-animation: ldio-i34nyigc4n 1.7241379310344827s cubic-bezier(0.3, 0, 0.7, 1) infinite;
    -o-animation: ldio-i34nyigc4n 1.7241379310344827s cubic-bezier(0.3, 0, 0.7, 1) infinite;
    width: 2.4em;
    height: 2.4em;

    &:nth-of-type(4n) {
      background: #81a3bd;
    }

    &:nth-of-type(4n + 1) {
      background: #00c0cb;
      left: 3.1em;
      animation-delay: -0.5747126436781609s;
      -webkit-animation-delay: -0.5747126436781609s;
      -moz-animation-delay: -0.5747126436781609s;
      -o-animation-delay: -0.5747126436781609s;
    }

    &:nth-of-type(4n + 2) {
      background: #00c0cb;
      left: 6.1em;
      animation-delay: -0.28735632183908044s;
      -webkit-animation-delay: -0.28735632183908044s;
      -moz-animation-delay: -0.28735632183908044s;
    }

    &:nth-of-type(4n + 3) {
      background: #00c0cb;
      left: 9.1em;
      animation-delay: 0s;
      -webkit-animation-delay: 0s;
      -moz-animation-delay: 0s;
      -o-animation-delay: 0s;
    }
  }
}

.loading-start {
  width: 15em;
  height: 15em;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  position: absolute;
  z-index: 600;
  top: 62%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 0.625rem;

  @media (min-width: 1500px) {
    font-size: 0.625rem * 1.5;
  }
}

.loadingio-spinner-message-bs4v748lf9j {
  width: 15em;
  height: 15em;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
