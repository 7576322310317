.flowplayer {
    * {
      font-weight: inherit;
      font-family: inherit;
      font-style: inherit;
      text-decoration: inherit;
      font-size: 100%;
      padding: 0;
      border: 0;
      margin: 0;
      list-style-type: none;
    }
    a {
      background-image: none !important;
    }
  }
.flowplayer .fp-player {
    background: black;
    video {
      position: fixed;
      top: 0;
      width: 100%;
    }
  }
.fp-pause{
    display: none;
}
.is-splash.flowplayer .fp-ui, .is-paused.flowplayer .fp-ui {
    background: none!important;
    background-size: 11%;
}
.fp-controls, .fp-time, .fp-fullscreen, .fp-embed{
    display: none!important; 
 }

.flowplayer.is-loading .fp-waiting,
.flowplayer.is-seeking .fp-waiting {
  display: block;
}
.flowplayer .fp-waiting svg {
  height: 0;
}
.fp-menu{
  display: none;
}
