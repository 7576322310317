// $box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
$padding-base: 1.2em;
$success-color: rgba(76, 175, 80, 90%);
$danger-color: rgba(244, 67, 54, 80%);
$text-color: rgba(0, 0, 0, 80%);
$border-radius: 0.1em;

@import '../media_queries';

.alertify-logs {
  @include breakpointsForTV(0.625rem);

  > * {
    // padding: $padding-base $padding-base * 2;
    color: #fff;
    //box-shadow: $box-shadow;
    border-radius: $border-radius;
    transition: 0.2s all;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-transition: 0.2s all;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    display: block !important;
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;

    &,
    &.default {
      // background: rgba(0, 0, 0, .8);
      background: transparent;
    }

    &.error {
      background: $danger-color;
    }

    &.success {
      background: $success-color;
    }

    box-sizing: border-box;
    position: relative;
    clear: both;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &.show {
      margin-top: $padding-base;
      opacity: 1;
      max-height: 100em;
      padding: $padding-base;
      pointer-events: auto;
    }
  }

  position: fixed;
  z-index: 99999;

  &.bottom,
  &:not(.top) {
    bottom: 1.6em;
  }

  // All left positions.
  &.left,
  &:not(.right) {
    left: 1.6em;

    > * {
      float: left;
      transform: translate3d(0, 0, 0);
      height: auto;

      &.show {
        left: 0;
      }

      &,
      &.hide {
        left: -110%;
      }
    }
  }

  &.right {
    right: 3em !important;

    > * {
      float: right;
      transform: translate3d(0, 0, 0);

      &.show {
        right: 0;
        opacity: 1;
      }

      &,
      &.hide {
        right: -110%;
        opacity: 0;
      }
    }
  }

  &.top {
    top: 0;
    // Top left placement, use for transitions.
  }
}

.alertify {
  position: fixed;
  background-color: rgba(0, 0, 0, 30%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  box-sizing: border-box;

  // * {
  //   box-sizing: border-box;
  // }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  &,
  &.show {
    box-sizing: border-box;
    transition: all 0.33s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-transition: all 0.33s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .dialog {
    padding: 1.2em;
  }

  .dialog,
  .alert {
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    //@include transform(translateY(-50%));
    > * {
      width: 40em;
      max-width: 95%;
      margin: 0 auto;
      text-align: center;
      padding: $padding-base;
      background: #fff;
      //box-shadow: 0 2px 4px -1px rgba(0,0,0,.14), 0 4px 5px 0 rgba(0,0,0,.098), 0 1px 10px 0 rgba(0,0,0,.084);
    }

    .msg {
      padding: $padding-base;
      // margin-bottom: $padding-base;
      margin: 0;
      text-align: left;
    }

    input:not(.form-control) {
      margin-bottom: 1.5em;
      width: 100%;
      font-size: 100%;
      padding: $padding-base;

      &:focus {
        outline-offset: -0.2em;
      }
    }

    nav {
      text-align: right;

      button:not(.btn, .pure-button, .md-button, .mdl-button) {
        background: transparent;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 87%);
        position: relative;
        outline: 0;
        display: inline-block;
        align-items: center;
        padding: 0 0.6em;
        margin: 0.6em 0.8em;
        line-height: 3.6em;
        min-height: 3.6em;
        white-space: nowrap;
        min-width: 8.8em;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.4em;
        text-decoration: none;
        cursor: pointer;
        border: 0.1em solid transparent;
        border-radius: 0.2em;

        &:hover,
        &:active {
          background-color: rgba(0, 0, 0, 5%);
        }

        &:focus {
          border: 1px solid rgba(0, 0, 0, 10%);
        }
      }

      button.btn {
        margin: 0.6em 0.4em;
      }
    }
  }
}
