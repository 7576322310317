$mainFontSize: 0.625rem; // 0.625rem = 10px
/* stylelint-disable-next-line */
$mainBorderWidth: 4px;

// mixin for setting breakpoint per font-size
@mixin breakpointsForTV($fontSize) {
  font-size: $fontSize;

  @media (max-width: 801px) {
    font-size: $fontSize * 0.625;
  }

  @media (min-width: 960px) and (max-width: 1200px) {
    font-size: $fontSize * 0.75;
  }

  @media (min-width: 1500px) and (max-width: 2000px) {
    font-size: $fontSize * 1.5;
  }

  @media (min-width: 2500px) {
    font-size: $fontSize * 2;
  }
}

// mixin for set automatic border with and responsive
@mixin borderWidthResponsive() {
  border-width: $mainBorderWidth;

  @media (max-width: 801px) {
    border-width: $mainBorderWidth * 0.625;
  }

  @media (min-width: 960px) and (max-width: 1200px) {
    border-width: $mainBorderWidth * 0.75;
  }

  @media (min-width: 1500px) and (max-width: 2000px) {
    border-width: $mainBorderWidth * 1.5;
  }

  @media (min-width: 2500px) {
    border-width: $mainBorderWidth * 2;
  }
}
