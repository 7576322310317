@use 'sass:math';
$layout-margin-left: 4.1%;
$layout-margin-right: 4.1%;
$width-total-cols: 86.3%;
$margin-total-cols: 100%-$width-total-cols;

@mixin calc-col-width($cols) {
  width: ((math.div($width-total-cols, 12)) * ($cols)) + ((math.div($margin-total-cols, 11)) * ($cols - 1));
  width: calc(#{$width-total-cols}/ 12 * (#{$cols}) + (#{$margin-total-cols}/ 11) * (#{$cols} - 1));
}

.layout {
  position: absolute;
  top: 0;
  /* stylelint-disable-next-line unit-disallowed-list */
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: black;
  padding: 3.1% $layout-margin-right 3.1% $layout-margin-left;

  .row {
    width: ((100% - ($layout-margin-left * 2)));
    display: block;

    .col {
      width: math.div($width-total-cols, 12);
      width: calc(#{$width-total-cols} / 12);
      /* stylelint-disable-next-line unit-disallowed-list */
      min-height: 50px;
      height: 100%;
      position: relative;
      display: inline-block;
      margin-right: math.div($margin-total-cols, 11);
      margin-right: calc(#{$margin-total-cols}/ 11);

      &.col-1 {
        width: (math.div($width-total-cols, 12));
        width: calc(#{$width-total-cols}/ 12);
      }

      &.col-2 {
        @include calc-col-width(2);
      }

      &.col-3 {
        @include calc-col-width(3);
      }

      &.col-4 {
        @include calc-col-width(4);
      }

      &.col-5 {
        @include calc-col-width(5);
      }

      &.col-6 {
        @include calc-col-width(6);
      }

      &.col-8 {
        @include calc-col-width(8);
      }

      &:last-child {
        margin-right: 0;
      }
      //   &.red {
      //     background-color: red;
      //   }
      //   &.yellow {
      //     background-color: yellow;
      //   }
      //   &.green {
      //     background-color: green;
      //   }
      //   &.blue {
      //     background-color: blue;
      //   }
    }
  }
}
