@import 'alertify';
$height-notifications: 8em;
$height-messages: 8em;

.notification {
  display: inline-block;
  width: 52em;
  // left:100px;
  //margin-left:50px;
  min-height: $height-notifications;
  justify-content: center;
  align-items: center;
  // background: white;
  // background: rgba(234, 234, 234, 0.11);
  border-radius: 0.75rem;
  position: relative;

  &.report-song {
    width: 38em;
  }

  p {
    display: inline-block;
    width: 65%;
    padding: 0 1rem 0 0.25rem;
    //font-size: large;
    margin: 0;
    color: black;
    font-family: Montserrat, Montserrat-Light;
    line-height: 1.5em;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    vertical-align: middle;
    font-weight: 600;
    font-size: 1.5em;

    &.freemiun-text {
      font-weight: 700;
      width: 75%;
      margin-left: 0.3em;
    }

    &.play-store {
      font-weight: 400;
      font-size: 1.4em;
    }

    span {
      font-family: Montserrat, Montserrat-Bold;
    }
  }

  .wrapicon {
    position: relative;
    display: inline-block;
    // margin-top: 0.8em;
    border-radius: 0.8em 0 0 0.8em;
    width: 8.8em;
    text-align: center;
    // height: $height-notifications * 0.8;
    // min-height: $height-notifications * 0.8;
    height: 6em;
    min-height: 6em;
    margin-top: 0.95em;
    margin-left: 0.8em;

    &.wrapicon-freemium {
      height: 6em;
      min-height: 6em;
      margin-top: 0.95em;
      margin-left: 0.8em;
    }

    span {
      display: inline-block;
      height: 100%;
      width: 100%;
      //top: 0;
      //bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      &.check-icon {
        background-image: url(../assets/img/notifications/check-blue.png);
      }

      &.play-store-icon {
        background-image: url(../assets/img/notifications/play-store.png);
      }

      &.msj-icon {
        background-image: url(../assets/img/notifications/msj-icon.png);
      }

      &.smart-list-icon {
        background-image: url(../assets/img/notifications/smartlist.png);
      }

      &.addfavorite-icon {
        background-image: url(../assets/img/notifications/save-favorite.png);
      }

      &.appmovil-icon {
        background-image: url(../assets/img/notifications/logoapp.png);
      }

      &.register-freemium {
        background-image: url(../assets/img/notifications/register-freemium.png);
        background-size: contain;
      }

      &.report-song {
        background-image: url('../assets/img/notifications/report-song.png');
        background-size: contain;
      }

      &.register-freemium-like {
        background-image: url(../assets/img/notifications/like-purple.png);
        background-size: contain;
      }

      &.register-freemium-follow {
        background-image: url(../assets/img/notifications/follow-purple.png);
        background-size: contain;
      }

      &.register-freemium-music {
        background-image: url(../assets/img/notifications/song.png);
        background-size: contain;
      }

      &.register-freemium-favorites {
        background-image: url(../assets/img/notifications/save.png);
        background-size: contain;
      }

      &.register-freemium-connect {
        background-image: url(../assets/img/notifications/phone.png);
        background-size: contain;
      }

      &.song-icon {
        background-image: url(../assets/img/notifications/check-blue.png);
      }

      &.exclamation-icon {
        background-image: url(../assets/img/notifications/alert.png);
      }

      &.follow-icon {
        background-image: url(../assets/img/notifications/follow.png);
      }

      &.addfriend-icon {
        background-image: url(../assets/img/notifications/addfriend.png);
      }

      &.lessfriend-icon {
        background-image: url(../assets/img/notifications/lessfriend.png);
      }

      &.email-icon {
        background-image: url(../assets/img/notifications/sendmail.png);
      }

      &.concert-icon {
        background-image: url(../assets/img/notifications/icon-notification.png);
      }

      &.lock-icon {
        background-image: url(../assets/img/notifications/notif-lock.png);
      }

      &.photo {
        width: 5.2em;
        height: 5.2em;
        border-radius: 50%;
        background-size: contain;
        margin: auto;
        margin-top: 0.64em;
      }

      &.addlist-icon {
        position: absolute;
        width: 2.4em;
        height: 2.4em;
        background-image: url(../assets/img/notifications/smartlist.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 4em;
        right: 1.6em;
        bottom: 0.4em;
      }

      &.like {
        background-image: url(../assets/img/notifications/like.png);
      }
    }
  }
}

.notification-message {
  display: inline-block;
  width: 36em;
  //margin-left: 50px;
  top: -12em;
  height: $height-messages;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  background: transparent;

  .user {
    display: inline-block;
    display: inline-flex;
    vertical-align: middle;

    .profile-picture {
      background: transparent;
      min-height: 1.6em;
      min-width: 1.6em;
      max-height: 1.6em;
      max-width: 1.6em;
      border-radius: 50%;
      font-size: 5em;
      text-align: center;
    }
  }

  .message-text {
    margin-left: 1em;
    display: inline-block;
    display: inline-flex;
    max-width: 65%;
    color: white;
    vertical-align: middle;
    font-size: 1.5em;
    flex-direction: column;
    overflow: hidden;
    max-height: $height-messages;

    .username {
      margin: 0;
      font-size: 1.2em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    p {
      text-overflow: ellipsis;
      //display: block;
      max-height: 1.5em * 2;
      display: box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.alertify-logs {
  z-index: 1000;

  > .show {
    padding: 0;
    border-radius: 0.5rem;
    margin-top: 1.75rem;
  }

  > .default {
    .notification {
      background: white;
    }

    .notification-message {
      background: transparent;
    }

    height: $height-notifications;
  }

  &.right {
    right: -0.8rem;

    .show {
      opacity: 1;
    }
  }
}
