$ks-width: 100%;
$ks-height: 100%;

@font-face {
  font-family: 'HelveticaNeue bold';
  src: url(/images/app/fonts/HelveticaNeue-Bold.ttf);
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(/images/app/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url(/images/app/fonts/Montserrat-Light.otf);
}

@import '../assets/fonts/montserrat';

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Montserrat-Light';
  //background-image:url(./images/app/fondos/bg.jpg);
  background-color: #16161b;
  overflow: hidden;
  height: 100%;
  // Calculo em para responsive
  // TODO: usar en el body el mismo calculo para usar luego medidas rem
  // font-size: 16px;
  // @media (min-width: 1501px) {
  //   font-size: (16'px'*1.5);
  // }
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: 0;
  }
}

input::-moz-selection {
  background: transparent;
}

input::selection {
  background: transparent;
}

input:focus {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}
/* stylelint-disable */
::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 174, 188, 1);
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* stylelint-enable */

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}
// Browser Prefixes
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
  -webkit-transform: $transforms;
}

// Rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// Skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}
// Transform Origin
@mixin border-radius($x) {
  -webkit-border-radius: $x;
  -moz-border-radius: $x;
  -ms-border-radius: $x;
  border-radius: $x;
}
$ksFocus: #00aebc;
$ksColor2: #f42434;

@import 'images/images';

.screen {
  width: $ks-width;
  position: absolute;
  height: $ks-height;
  margin: auto;
  //background-image:url(./images/app/fondos/bg.jpg);
  background-color: #16161b;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* stylelint-disable */
.content {
  width: 935px;
  height: 695px;
  float: left;
}

.txt-green {
  color: $ksFocus;
}

.icon.icon-coutry {
  height: 11px;
}
/* stylelint-enable */

.btn {
  &.focus-item-keyboard {
    border: 5px solid #02aebc !important;
  }
}

/* .notification p.notification-text{
  position: absolute;
  padding: 0 20px;
  top:0;
} */

.app-logo {
  background-image: $app-logo;

  &.beta {
    background-image: $app-logo-beta;
  }
}

.sprite-ks {
  background: url(/images/app/sprite-ks.png);
  background-repeat: no-repeat;
  background-size: cover;
}
//MOdal Verificacion
.modal-verification {
  position: fixed;
  z-index: 11;

  .modal-verification__backfond {
    background: rgba(0, 0, 0, 0.95) 50% 50% no-repeat !important;
    position: fixed;
    left: 0;
    top: 0;
    height: $ks-height;
    width: $ks-width;
    z-index: 10;

    &.modal-verification--left0pxWebos {
      left: 0;
    }
  }

  /* stylelint-disable */
  .modal {
    .modal__mcontent {
      height: 200px;
      width: 900px;
      background: transparent;
      margin: 180px auto;
      border: 4px solid transparent;
      font-family: 'Lato Light';

      .left {
        float: left;
        //color: rgb(2, 191, 210);
        //color: rgb(120, 120, 120);
        //color: rgb(120, 120, 120);
        color: white;

        strong {
          font-size: 35px;
          margin: 45px 6px 0 20px;
          display: inline-block;
        }

        span {
          font-size: 35px;
        }

        p {
          margin: 45px 0 0 20px;
          font-size: 20px;
        }
      }

      .right {
        float: right;
      }
    }
  }
}

//.modal-verification .modal .modal__mcontent .left
.modal.modal-verification__backfond {
  .modal__mcontent {
    .left.txt-center {
      width: 100%;
      margin-left: 0;
      padding: 0 15px;
      box-sizing: border-box;
      text-align: center;
      margin-top: 40px;
    }
  }
}

/* stylelint-enable */

.modal-init {
  height: 100%;
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  transition-property: transform;
  transition-duration: 1s;

  &.app-loaded {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
  }

  .modal-verification__backfond {
    //    background: rgba(0,0,0,0.85) 50% 50% no-repeat !important;
    background-image: $img-load-init;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: $ks-height;
    width: $ks-width;
    z-index: 500;
  }
}

@import 'loader';

.hidden {
  display: none;
}

@import '../_basics';
